import { useEffect } from 'react';
import './App.css';
import Leader from './components/Leader/leader';
import Placement from './components/Placement/placement';
import { useState } from 'react';
import fetch from 'node-fetch';

function App() {

  const [ selected, setSelected ] = useState('current')
  const [ current, setCurrent ] = useState(null)
  const [ prev, setPrev ] = useState(null)
  const [ timeLeft, setTimeLeft ] = useState()

  useEffect(() => {
    getLeaderboards()
  }, [])

  useEffect(() => {
    if (!current) return

    const interval = setInterval(() => {
      let secondsLeft = Math.floor((new Date(current.isoEnd).getTime() - Date.now()) / 1000)
      setTimeLeft(secondsLeft)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [ current ])

  async function getLeaderboards() {
    const req = await fetch(`${process.env.REACT_APP_BACKEND_URL}/leaderboards`)
    if (req.status !== 200) return
    const res = await req.json()

    let secondsLeft = Math.floor((new Date(res.currentLeaderboard.isoEnd).getTime() - Date.now()) / 1000)
    setTimeLeft(secondsLeft)

    setCurrent(res.currentLeaderboard)
    setPrev(res.previousLeaderboard)
  }

  function getDays() {
    if (!current || selected !== 'current' || timeLeft <= 0) return '00'
    return `${Math.floor(timeLeft / 24 / 60 / 60)}`.padStart(2, '0')
  }

  function getHours() {
    if (!current || selected !== 'current' || timeLeft <= 0) return '00'
    return `${Math.floor(timeLeft / 60 / 60) % 24}`.padStart(2, '0')
  }

  function getMinutes() {
    if (!current || selected !== 'current' || timeLeft <= 0) return '00'
    return `${Math.floor(timeLeft / 60) % 60}`.padStart(2, '0')
  }

  function getSeconds() {
    if (!current || selected !== 'current' || timeLeft <= 0) return '00'
    return `${timeLeft % 60}`.padStart(2, '0')
  }

  return (
    <div className="content">
      <img src='magiclogo.webp'/>

      <div className='leaderboardSelector'>
        <button className={selected === 'previous' ? 'active' : '' } onClick={() => setSelected('previous')}>Previous</button>
        <button className={selected === 'current' ? 'active' : '' } onClick={() => setSelected('current')}>Current</button>
        <div className={`selector ${selected === 'current' ? 'active' : ''}`}/>
      </div>

      <div className='information'>
        <h1>
          {current && (
            <span className='highlight'>${current.rewards.reduce((pv, v) => pv + v, 0)} </span> 
          )}
          WEEKLY LEADERBOARD
        </h1>
        <p className='instructions'>Every bet under code <span className='highlight'>jamey</span> counts towards your wager</p>
      </div>

      <div className='timer'>
        <p>{getDays()}d</p>
        <p>{getHours()}h</p>
        <p>{getMinutes()}m</p>
        <p>{getSeconds()}s</p>
      </div>

      <div className='leaders'>
        {selected === 'current' ? (
          <>
            <Leader user={current?.users[1]} reward={current?.rewards[1]}/>
            <Leader user={current?.users[0]} reward={current?.rewards[0]}/>
            <Leader user={current?.users[2]} reward={current?.rewards[2]}/>
          </>
        ) : (
          <>
            <Leader user={prev?.users[1]} reward={current?.rewards[1]}/>
            <Leader user={prev?.users[0]} reward={current?.rewards[0]}/>
            <Leader user={prev?.users[2]} reward={current?.rewards[2]}/>
          </>
        )}
      </div>

      <div className='placements'>
        {selected === 'current' ? (
          <>
            {Array.isArray(current?.users) && current?.users?.slice(3, 10).map((user, index) => (
              <Placement user={user} reward={current.rewards[index + 3]} spot={index + 4}/>
            ))}
          </>
        ) : (
          <>
            {Array.isArray(prev?.users) && prev?.users?.slice(3, 10).map((user, index) => (
              <Placement user={user} reward={prev.rewards[index + 3]} spot={index + 4}/>
            ))}
          </>
        )}
      </div>
      
      <div className='top-left-gradients'>
        <div className='blue-gradient'/>
        <div className='red-gradient'/>
      </div>

      <div className='top-right-gradients'>
        <div className='blue-gradient'/>
        <div className='red-gradient'/>
      </div>
    </div>
  );
}

export default App;
