import styles from './placement.module.css'

export default function Placement({ user, reward, spot }) {
    return (
        <div className={styles.placementContainer}>
            <p>
                <span className={styles.place} style={{color: 'rgb(151, 147, 186)', fontWeight: '500'}}>{spot}th</span>
                <img className={styles.avatar} src={user.avatar} height='30' width='30'/>
                <span className={styles.username} style={{fontWeight: '600'}}>{user.username}</span>
            </p>

            <p style={{ justifyContent: 'center' }}>
                <img src='magiccoin.svg' height='16'/>
                {user.totalWagered.toLocaleString()}
            </p>

            <p style={{color: 'rgb(165, 236, 96)', justifyContent: 'flex-end'}}>
                <img src='magiccoin.svg' height='16'/>
                {reward}
            </p>
        </div>
    )
}