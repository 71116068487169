import styles from './leader.module.css'

export default function Leader({ user, reward }) {
    return (
        <div className={styles.leaderContainer}>
            <div className={styles.avatar}>
                {user ? (
                    <img src={user?.avatar} height='80' width='80'/>
                ) : (
                    <p>?</p>
                )}
            </div>
            <p className={styles.username}>{user?.username || 'No one...'}</p>
            <p>Wagered</p>
            <p className={styles.wagered}>
                <img src='magiccoin.svg' height='16'/>
                {(user?.totalWagered || 0).toLocaleString()}
            </p>
            <div className={styles.line}/>
            <p>Reward</p>
            <p className={styles.wagered} style={{color: 'rgb(165, 236, 96)'}}>
                <img src='magiccoin.svg' height='16'/>
                {reward || 0}
            </p>
        </div>
    )
}